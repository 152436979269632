import Vue from "vue";
import Router from "vue-router";
import { getToken } from './auth.js'
const login = () => import("./views/login.vue");
const index = () => import("./views/index.vue");
const details = () => import("./views/details.vue");
Vue.use(Router);
const whiteList = ['/login']
let router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [{
        path: "/",
        name: "index",
        component: index

    },
    {
        path: "/login",
        name: "login",
        component: login
    },
    {
        path: "/details/:id/:type/:typeName/:done/:taskId",
        name: "details",
        component: details
    },


    ]
});

router.beforeEach((to, from, next) => {
    if (getToken()) {
        if (to.path === '/login') {
            next({ path: '/' })
        } else {
            next()
        }
    } else {
        // 没有token
        if (whiteList.indexOf(to.path) !== -1) {
            // 在免登录白名单，直接进入
            next()
        } else {
            next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
        }
    }
})


export default router;