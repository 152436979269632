<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      audios: process.env.VUE_APP_image_URL + "audio_1.mp3",
    };
  },
  mounted() {
    document.documentElement.style.fontSize = this.myrem();
    window.onresize = () => {
      document.documentElement.style.fontSize = this.myrem();
    };
  },
  methods: {
    myrem() {
      let myW = document.body.clientWidth * 2;
      let rem = (myW * 100) / 750 + "px";
      return rem;
    },
  },
};
</script>

<style>
* {
  margin: 0 0;
}
html,
body,
#app {
  width: 100%;
  height: 100%;
  max-width: 600px;
  margin: 0 auto;
  font-family: "Microsoft YaHei", "STHeiti Light", Arial, sans-serif;
}
</style>

